import axios from "axios";
import router from "@/router";
import { Message } from 'view-ui-plus'

// var baseUrl = "http://39.99.229.244:8005/";
// var baseUrl = "https://xnhyl.hljzzjt.com/";
var baseUrl = "https://xnhyl.hljzzjt.com/";
// var baseUrl = "http://192.168.1.124:5005/";
var hostName = document.location.hostname;

if (hostName.indexOf("127.0.0.1") >= 0 ||
  hostName.indexOf("192.168.") >= 0 ||
  hostName.indexOf("localhost") >= 0) {
  // baseUrl = "http://192.168.1.124:5005/";
  // baseUrl = "http://39.99.229.244:8005/";
  baseUrl = "https://xnhyl.hljzzjt.com/";
}

const service = axios.create({
  baseURL: baseUrl,
  withCredentials: false,
})
service.interceptors.request.use(function (req) {
  req.headers.Authorization = `Bearer ${localStorage.getItem('Authorization')}`;
  if (req.headers.responseType) {
    req.responseType = 'blob';
  }
  // console.log(req);
  return req;
}, function (error) {
  return Promise.reject(error);
});
service.interceptors.response.use(function (response) {
  const res = response.data;
  if (res.code != 200) {
  //   if (!res.code) {
  //     return res;
  //   }
    if (res.code == 401) {
      router.push('/login')
      return false;
    }
  //   if (res.code == 500) {
      Message.error(res.message || '')
      return false;
  //   }
  //   if (res.code == 403) {
  //     return false;
  //   }
  //   return res;
  }
  return res;
}, function (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = "请求错误(400)"
        break
      case 401:
        error.message = "未授权,请登录(401)"
        break
      case 403:
        error.message = "拒绝访问(403)"
        break
      case 404:
        error.message = `请求地址出错: ${error.response.config.url}`
        break
      case 405:
        error.message = "请求方法未允许(405)"
        break
      case 408:
        error.message = "请求超时(408)"
        break
      case 500:
        error.message = "服务器内部错误(500)"
        break
      case 501:
        error.message = "服务未实现(501)"
        break
      case 502:
        error.message = "网络错误(502)"
        break
      case 503:
        error.message = "服务不可用(503)"
        break
      case 504:
        error.message = "网络超时(504)"
        break
      case 505:
        error.message = "HTTP版本不受支持(505)"
        break
      default:
        error.message = `连接错误: ${error.message}`
    }
  } else {
    if (error.message == "Network Error") error.message == "网络异常，请检查后重试！"
    error.message = "连接到服务器失败，请联系管理员"
  }
  return Promise.reject(error);
});

export default service
